import React from 'react'
import { Link } from 'gatsby'

const Nav = () => {
    return (
        <div>
            <div className="static hidden h-full md:flex w-auto  md:flex-row sm:px-24 md:px-24 py-2  sm:py-32 bg-red-700 font-semibold text-white border-b border-red-500 sm:border-r sm:border-red-500 shadow-lg sm:shadow-2xl">
            <ul className="fixed top-auto w-full justify-center sm:w-auto bg-red-700 sm:bg-transparent flex flex-row justify-around sm:justify-start  sm:flex-col text-lg shadow-lg sm:shadow-none border-b border-red-500 sm:border-none">
                <li className="py-4 px-2">
                <Link to="/">Home</Link>
                </li>
                {/* <li className="py-4 px-2">About</li> */}
                <li className="py-4 px-2">
                <Link to="/blog">Blog</Link>
                </li>
                <li className="py-4 px-2">
                <Link to="/contact">Contact</Link>
                </li>
            </ul>
            </div>
            <ul className="fixed z-20 top-auto w-full justify-center font-semibold text-white md:hidden md:w-auto bg-red-700 md:bg-transparent flex flex-row justify-around md:justify-start  md:flex-col text-lg shadow-lg md:shadow-none border-b border-red-500 md:border-none">
                <li className="py-4 px-2">
                    <Link to="/">Home</Link>
                </li>
                {/* <li className="py-4 px-2">About</li> */}
                <li className="py-4 px-2">
                    <Link to="/blog">Blog</Link>
                </li>
                {/* <li className="py-4 px-2">
                <Link to="/contact">Contact</Link>
                </li> */}
            </ul>
        </div>
    )
}

export default Nav