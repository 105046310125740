/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Nav from "./nav"

import "../css/global.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="flex flex-col sm:flex-row bg-red-600 min-h-screen">
      <Nav />
      {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
      <div className="flex flex-col mx-8 my-12 md:my-0 ">
        <div>
          <h1 className=" w-full  m-8 font-black text-6xl text-white underline">
            {data.site.siteMetadata.title}
          </h1>
        </div>
        <div>
          <main className="min-h-screen mx-8">{children}</main>
          <footer className="p-8">
            © {new Date().getFullYear()}, Built with
            {` `}
            <a href="https://www.gatsbyjs.org">Gatsby - </a>
            <span>Imagined by Devon</span>
          </footer>
        </div>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
